<template>
  <div class="invoiceTax">
    <div id="invoice" class="invoiceBG">
      <div class="text-center" style="margin-top: 5%">
        <img
          class="imgLogo"
          src="../assets/thestrand-logo.png"
          alt="thestrartLogo"
        />
      </div>

      <div style="text-align: right">
        <!-- <p class="receipt-custom">
          Receipt No: {{ receiptNo }}
        </p> -->
        <p class="date-custom">Date: {{ date }}</p>
      </div>

      <div class="text-center project-data">
        <p style="margin-bottom: 2px">
          {{ projectName }}
        </p>
        <p style="margin-bottom: 2px">Branch : {{ branch }}</p>
        <p style="margin-bottom: 2px">
          {{ projectAddress }}
        </p>
        <p style="margin-bottom: 2px">Tax ID: {{ taxId }}</p>
        <p v-if="abbId != ''" style="margin-bottom: 2px">POS: {{ posId }}</p>
        <p v-if="abbId != ''" style="margin-bottom: 2px">
          tax(ABB): {{ abbId }}
        </p>
        <p
          v-if="abbId != ''"
          style="font-weight: bold; margin: 30px; font-size: 14px"
        >
          Receipt/Tax Invoice (ABB)
        </p>
        <p v-else style="font-weight: bold; margin: 30px; font-size: 14px">
          Receipt
        </p>
      </div>
      <div class="custom-line">
        <div v-for="(title, key) in invoiceTitle" :key="key" class="row">
          <div class="column-invoice-left" v-t="title"></div>
          <div class="column-invoice-right">
            {{ invoiceData[key] }}
          </div>
        </div>
      </div>
      <div style="width: 90%; margin: auto">
        <div class="row">
          <div class="column-invoice-left">
            {{ $t("calLabel11") }}
          </div>
          <div class="column-invoice-right">
            {{ invoiceData.totalFee }} {{ invoiceData.currencyCode }}
          </div>
          <div class="column-invoice-left">
            {{ $t("penaltyLabel") }}
          </div>
          <div class="column-invoice-right">
            0 {{ invoiceData.currencyCode }} N
          </div>
        </div>
        <p class="non-vat-text">(N = Non VAT)</p>
      </div>

      <p class="text-center vat-font">VAT. INCLUDED</p>
    </div>

    <div
      class="row"
      style="margin-top: 30px; margin-left: 0px; margin-right: 0px"
    >
      <div class="column-btn-invoice">
        <button class="btn-download-invoice" @click="downloadInvoice">
          <img
            src="../assets/download-logo.png"
            style="
              width: 16px;
              vertical-align: middle;
              display: inline-block;
              margin-right: 10px;
            "
          />
          <span>{{ $t("btnSaveInvoice") }}</span>
        </button>
      </div>
    </div>

    <button type="button" class="btn-gohome" @click="goHome">
      {{ $t("btnbacktoHome") }}
    </button>

    <!-- <div class="policy-custom">
      <a
        href="/condition"
        style="color: #5c5b5b; padding-bottom: 10%"
      >{{ $t('parkingCondition') }}</a>
    </div> -->
  </div>
</template>

<script>
import domtoimage from "dom-to-image";
export default {
  name: "TaxInvoice",
  data() {
    return {
      imgInvoice: "",
      projectName: "",
      projectAddress: "",
      receiptNo: "",
      date: "",
      taxId: "",
      posId: "",
      abbId: "",
      branch: "",
      invoiceTitle: {
        licensePlate: "calLabel6",
        entryTime: "calLabel8",
        exitTime: "calLabel9",
        parkingTime: "calLabel10",
        paymentMethod: "paymentMethodLabel",
      },
      invoiceData: {
        licensePlate: "",
        entryTime: "",
        exitTime: "",
        parkingTime: "",
        paymentMethod: "",
        totalFee: "",
        currencyCode: "",
      },
      calData: {},
      paymentInfo: {},
    };
  },
  created() {
    if (localStorage.getItem("calData")) {
      this.calData = localStorage.getItem("calData");
      this.calData = JSON.parse(this.calData);

      this.projectName = this.calData.project_name;
      this.projectAddress = this.calData.project_address;
      this.receiptNo = this.calData.receipt_no;
      this.date = this.getCurrentDate();
      this.taxId = this.calData.tax_id;
      this.posId = this.calData.pos_id;
      this.branch = this.calData.branch_id;
      this.invoiceData.licensePlate = this.calData.plate_no;

      this.invoiceData.currencyCode = this.calData.currency_code;
      this.invoiceData.parkingTime = this.calData.parking_time + " hours";
    } else {
      this.$router.push({
        name: "calparking",
      });
    }

    if (localStorage.getItem("abbTaxID")) {
      this.abbId = localStorage.getItem("abbTaxID").replace(/\"/g, "");
    }

    if (localStorage.getItem("paymentMethod")) {
      this.invoiceData.paymentMethod = localStorage
        .getItem("paymentMethod")
        .replace(/\"/g, "");
    }

    if (localStorage.getItem("paymentInfo")) {
      this.paymentInfo = localStorage.getItem("paymentInfo");
      this.paymentInfo = JSON.parse(this.paymentInfo);

      this.invoiceData.entryTime = this.dateFormat(
        this.paymentInfo.parking_time,
        "dd-MM-yyyy H:i"
      );
      this.invoiceData.exitTime = this.calData.car_out_time;

      this.invoiceData.totalFee = this.paymentInfo.net_parking_fee;
      this.receiptNo = this.paymentInfo.receipt_no;
    }
  },
  methods: {
    getCurrentDate() {
      const d = new Date();

      const date = ("0" + d.getDate()).slice(-2);
      const month = ("0" + (d.getMonth() + 1)).slice(-2);

      const currentDate = date + "-" + month + "-" + d.getFullYear();
      return currentDate;
    },
    downloadInvoice() {
      const node = document.getElementById("invoice");

      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          const linkSource = dataUrl;
          const downloadLink = document.createElement("a");
          downloadLink.href = linkSource;
          downloadLink.download = "The-Strand-Invoice.png";
          downloadLink.click();
        })
        .catch(function (error) {
          console.error("oops, something went wrong!", error);
        });
    },
    goHome() {
      this.$router.push({
        name: "calparking",
      });
    },
    dateFormat(inputDate, format) {
      // parse the input date
      console.log(inputDate);
      const date = new Date(inputDate.replace(/\s/, "T"));

      // extract the parts of the date
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hours = date.getHours();
      const minute = date.getMinutes();

      // replace the month
      format = format.replace("MM", month.toString().padStart(2, "0"));

      // replace the year
      if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
      } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
      }

      // replace the day
      format = format.replace("dd", day.toString().padStart(2, "0"));

      // replace hour
      format = format.replace("H", hours.toString().padStart(2, "0"));

      // replace minute
      format = format.replace("i", minute.toString().padStart(2, "0"));

      return format;
    },
  },
};
</script>

<style>
.invoiceBG {
  width: 100%;
  height: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 20px;
  padding-top: 30px;
  margin-right: 0;
}

.invoiceTax {
  min-height: 100vh;
  background-color: white !important;
  padding: 50px;
}

.imgLogo {
  width: 130px;
  height: auto;
  margin-bottom: 25px;
}

.receipt-custom {
  /* top: 10%;
  right: 50%;
  transform: translate(130%, -240%); */
  font-size: 12px;
  margin-bottom: 0;
}

.date-custom {
  /* top: 10%;
  right: 50%;
  transform: translate(135%, -150%); */
  font-size: 12px;
}

.project-data {
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.custom-line {
  border-top-style: dashed;
  border-bottom-style: dashed;
  width: 90%;
  height: 240px;
  margin: auto;
  color: #4d4d4d !important;
  padding-top: 30px;
  margin-bottom: 30px;
}

.column-invoice-left {
  /* float: left; */
  width: 50% !important;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 20px;
}

.column-invoice-right {
  /* float: right; */
  text-align: right;
  width: 50% !important;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.vat-font {
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 30px;
  margin-bottom: 0px;
}

.btn-gohome {
  position: relative;
  background-color: #3f4955;
  color: white;
  padding: 10px;
  width: 80%;
  border-radius: 5px;
  border: none;
  bottom: -5%;
  left: 10%;
  margin-bottom: 30px;
}

.btn-gohome:hover {
  background: #323a44 !important;
  color: #ffffff !important;
}

.btn-download-invoice {
  background-color: #00c714;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.btn-download-invoice:hover {
  background: #00af12 !important;
  color: #ffffff !important;
}

.column-btn-invoice {
  width: 100% !important;
  text-align: center;
  padding: 0px !important;
  margin-bottom: 30px;
}

.non-vat-text {
  text-align: right;
  font-size: 12px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
</style>
