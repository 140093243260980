export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวณค่าที่จอดรถ"])},
  "textCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกเลขใต้ QR Code 4 หลัก หรือสแกน QR Code"])},
  "placeholderCalParking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรอกเลขใต้ QR Code 4 หลัก"])},
  "btnScanQR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแกน QR Code"])},
  "btnCalculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["คำนวณ"])},
  "calLabel1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["รหัส QR Code 4 หลัก"])},
  "calLabel2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชื่อ-นามสกุล"])},
  "calLabel3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เลขบัตรประชาชน / เลขที่หนังสือเดินทาง"])},
  "calLabel4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["หมายเลขห้อง"])},
  "calLabel5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยานพาหนะ"])},
  "calLabel6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ป้ายทะเบียน"])},
  "calLabel7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตราประทับ"])},
  "calLabel8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาเข้า"])},
  "calLabel9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เวลาออก"])},
  "calLabel10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ระยะเวลาการจอด"])},
  "calLabel11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าจอดสุทธิ"])},
  "calLabel12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ส่วนลด"])},
  "calLabel13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าที่จอดรถทั้งหมด"])},
  "btnPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงิน"])},
  "parkingCondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไขการจอดรถ"])},
  "paymentSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินสำเร็จ"])},
  "paymentDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณานำรถออกจากที่จอดรถ และนำ QR Code บนใบเสร็จไปสแกนที่ประตูรักษาความปลอดภัยขาออกหลังจากชำระเงิน"])},
  "sessionExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Session หมดอายุ"])},
  "sessionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการของ QR Code นี้หมดอายุแล้ว"])},
  "paymentAgain1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณากดปุ่ม "])},
  "paymentAgain2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" เพื่อใหม่ดำเนินการอีกครั้ง"])},
  "conditionLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อกำหนดและเงื่อนไข"])},
  "btnBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ย้อนกลับ"])},
  "paymentTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QR Code นี้จะหมดอายุภายใน"])},
  "timeUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["นาที"])},
  "btnSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึก QR Code"])},
  "descriptionPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแกน QR Code เพื่อชำระเงิน"])},
  "warningPayment1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณานำรถออกจากที่จอดรถ และนำ QR Code บนใบเสร็จ"])},
  "warningPayment2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ไปสแกนที่ประตูรักษาความปลอดภัยขาออกหลังจากชำระเงิน"])},
  "btnCancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยกเลิก"])},
  "btnCheckStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตรวจสอบการชำระเงิน"])},
  "btnOk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ตกลง"])},
  "errorTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ข้อผิดพลาด"])},
  "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["การดำเนินการชำระเงินหมดเวลาแล้ว กรุณาลองใหม่อีกครั้ง"])},
  "btnSaveInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["บันทึกใบเสร็จ"])},
  "btnbacktoHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กลับสู่หน้าหลัก"])},
  "paymentMethodLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["วิธีการชำระเงิน"])},
  "penaltyLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ค่าปรับ"])},
  "stampTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["สแตมป์ที่จอดรถ"])},
  "stampMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["เพื่อรักษาสิทธิ์ของคุณ คุณสามารถแสตมป์ที่จอดรถกับทางร้านค้าได้ หรือในกรณีที่ไม่แสตมป์กับร้านค้า คุณสามารถเลือกชำระเงินได้ทันที"])},
  "btnPayNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ชำระเงินทันที"])},
  "btnPayLater": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ยังไม่ชำระเงิน"])},
  "scanMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["กรุณาวาง QR Code ในตำแหน่งที่กำหนด"])}
}