<template>
  <div class="calparking">
    <h1 class="header-parking">
      {{ $t("title") }}
    </h1>
    <p class="text-parking">
      {{ $t("textCalParking") }}
    </p>

    <div class="input-parking">
      <input
        ref="pincodeEl"
        type="text"
        class="form-control size-input"
        :placeholder="$t('placeholderCalParking')"
        aria-label="pincode"
        aria-describedby="basic-addon1"
      />
    </div>
    <div v-if="messageError !== '' && paymentStatus !== 1" class="error-custom">
      {{ messageError }}
    </div>

    <div class="row" style="margin-top: 15px">
      <div class="column">
        <button class="btn-scan-qr" @click="qrcodeScanner">
          <img
            src="../assets/qrcode-logo.png"
            style="width: 16px; vertical-align: middle; display: inline-block"
          />
          <span class="labal-scanner"> {{ $t("btnScanQR") }}</span>
        </button>
      </div>
      <div class="column">
        <button
          id="calculate-btn"
          class="btn-calculate"
          @click="calculateParking"
        >
          <p class="labal-btn">
            {{ $t("btnCalculate") }}
          </p>
        </button>
      </div>
    </div>
    <!-- </div> -->
    <div class="loader-custom">
      <div
        id="loading"
        class="spinner-grow text-secondary"
        role="status"
        style="display: none"
      />
    </div>

    <div v-if="paymentStatus === 1">
      <div class="row" style="margin-top: 5%">
        <div class="column" style="width: 100% !important">
          <p class="payment-header">
            {{ $t("paymentSuccess") }}
          </p>
          <span
            ><img
              class="payment-success"
              src="../assets/success.png"
              alt="success"
          /></span>
        </div>
        <p class="payment-text">
          {{ $t("paymentDescription") }}
        </p>
      </div>
    </div>

    <div v-if="sessionStatus === 1">
      <div class="row" style="margin-top: 5%">
        <div class="column" style="width: 100% !important">
          <p class="session-header">
            {{ $t("sessionExpired") }}
          </p>
          <span
            ><img
              class="payment-error"
              src="../assets/error-logo.png"
              alt="error"
          /></span>
        </div>
        <p class="payment-text">
          {{ $t("sessionDescription") }}
        </p>
        <p class="payment-text">
          {{ $t("paymentAgain1") }}
          <span style="font-weight: bold">{{ $t("btnPayment") }}</span>
          {{ $t("paymentAgain2") }}.
        </p>
      </div>
    </div>

    <div v-if="status === 1">
      <div class="custom-table">
        <table>
          <tr v-for="(title, key) in calTitle" :key="key">
            <th v-t="title"></th>
            <td>{{ calData[key] }}</td>
          </tr>
        </table>
      </div>
      <span>
        <button id="payment-btn" class="btn-payment" @click="paymentParking">
          {{ $t("btnPayment") }}
        </button>
      </span>
    </div>
  </div>

  <div class="policy-custom">
    <a href="/condition" style="color: #5c5b5b">{{ $t("parkingCondition") }}</a>
  </div>

  <div
    id="payment-stamp"
    ref="paymentStamp"
    class="modal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header" style="border-bottom: 0">
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body text-center">
          <img
            class="errorLogo"
            src="../assets/stamp-logo.png"
            alt="stampLogo"
          />
          <h3 class="stamp-header">{{ $t("stampTitle") }}</h3>
          <p class="stamp-message">{{ $t("stampMessage") }}</p>
          <div>
            <button
              class="btn btn-stamp"
              data-bs-dismiss="modal"
              @click="payNow"
            >
              {{ $t("btnPayNow") }}
            </button>
          </div>

          <div style="margin-top: 5%; margin-bottom: 5%">
            <button
              class="btn btn-stamp"
              data-bs-dismiss="modal"
              @click="payLater"
            >
              {{ $t("btnPayLater") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from "bootstrap/dist/js/bootstrap.js";
import axios from "axios";
export default {
  name: "CalParking",
  props: ["qrkey", "sessionExpire", "pinCode"],
  data() {
    return {
      qrcodeKey: "",
      responseDataCal: {},
      responseDataPay: {},
      parkingCodition: [],
      status: 0,
      messageError: "",
      paymentStatus: 0,
      paymentBtnStatus: 0,
      sessionStatus: 0,
      calTitle: {
        pinCode: "calLabel1",
        // name: "calLabel2",
        // identityId: "calLabel3",
        // contact: "calLabel4",
        entryTime: "calLabel8",
        exitTime: "calLabel9",
        vehicle: "calLabel5",
        licensePlate: "calLabel6",
        shopName: "calLabel7",
        parkingTime: "calLabel10",
        totalParkingFee: "calLabel13",
        rebate: "calLabel12",
        totalFee: "calLabel11",
      },
      calData: {
        pinCode: "",
        name: "",
        identityId: "",
        contact: "",
        vehicle: "",
        licensePlate: "",
        shopName: "",
        entryTime: "",
        exitTime: "",
        parkingTime: "",
        totalFee: "",
        menchantId: "",
        posId: "",
        taxId: "",
        partnerUId: "",
        reference1: "",
        currencyCode: "",
        parkingFee: "",
        paymentId: "",
        parkingTimeHr: "",
        rebate: "",
        totalParkingFee: ""
      },
    };
  },
  created() {
    console.log(process.env.NODE_ENV);
    console.log("Clear local storage");
    localStorage.removeItem("calData");
    localStorage.removeItem("qrPayment");
    localStorage.removeItem("parkingCondition");

    this.$i18n.locale = this.$store.state.locale
    // console.log(this.sessionExpire)

    if (this.sessionExpire && this.sessionExpire == 1) {
      this.sessionStatus = 1;
      console.log("session status", this.sessionStatus);
    }

    if (this.pinCode) {
      this.calculateParking(this.pinCode);
    }

    if (this.qrkey) {
      this.calculateParking(this.qrkey);
    }
  },
  methods: {
    qrcodeScanner() {
      this.$router.push({ name: "qrscanner" });
    },
    async calculateParking(qrkey = null) {
      this.messageError = "";
      this.paymentStatus = 0;
      const loading = document.getElementById("loading");
      const calBtn = document.getElementById("calculate-btn");

      if (loading && calBtn) {
        loading.style.display = "block";
        calBtn.disabled = true;
      }

      const qs = require("qs");
      if (!this.$refs.pincodeEl) {
        this.qrcodeKey = qrkey;
      } else {
        this.qrcodeKey = this.$refs.pincodeEl.value;
      }

      const urlAPI = process.env.VUE_APP_ROOT_API + "/v2/car/cal-parking-fee";
      const dataPost = qs.stringify({
        qrkey: this.qrcodeKey,
        type: 0,
        channel: 5,
      });
      const headers = {
        Authorization: process.env.VUE_APP_AUTHORIZATION,
        "Content-Type": "application/x-www-form-urlencoded",
      };
      await axios
        .post(urlAPI, dataPost, { headers })
        .then((res) => {
          if (loading && calBtn) {
            loading.style.display = "none";
            calBtn.disabled = false;
          }
          this.responseDataCal = res.data;
          this.status = res.data.status;
          if (this.qrcodeKey == "") {
            this.calData.pinCode = this.qrcodeKey;
          } else if (this.responseDataCal.info.pin_code) {
            this.calData.pinCode = this.responseDataCal.info.pin_code;
          } else {
            this.calData.pinCode = "";
          }

          this.calData.name =
            this.responseDataCal.info.name != "" &&
            this.responseDataCal.info.name != " "
              ? this.responseDataCal.info.name
              : "";

          this.calData.identityId = this.responseDataCal.info.identity_id
            ? this.responseDataCal.info.identity_id
            : "";

          if (this.$store.state.locale == "th") {
            var unitTime = "ชั่วโมง";

            this.calData.vehicle =
              this.responseDataCal.info.car_value_en != ""
                ? this.responseDataCal.info.car_value_th
                : "";

            this.calData.totalFee =
              this.responseDataCal.info.parking_fee + " บาท";

            this.calData.rebate = this.responseDataCal.info.rebate + " บาท";

            this.calData.totalParkingFee = this.responseDataCal.info.total_parking_fee + " บาท";
          } else {
            var unitTime = "hours";

            this.calData.vehicle =
              this.responseDataCal.info.car_value_en != ""
                ? this.responseDataCal.info.car_value_en
                : "";

            this.calData.totalFee =
              this.responseDataCal.info.parking_fee +
              " " +
              this.responseDataCal.info.currency_code;

            this.calData.rebate = this.responseDataCal.info.rebate + " " + this.responseDataCal.info.currency_code;

            this.calData.totalParkingFee = this.responseDataCal.info.total_parking_fee + " " + this.responseDataCal.info.currency_code;
          }

          this.calData.licensePlate =
            this.responseDataCal.info.plate_no != ""
              ? this.responseDataCal.info.plate_no
              : "";

          this.calData.contact = this.responseDataCal.info.contact;
          this.calData.entryTime = this.responseDataCal.info.register_time;
          this.calData.exitTime = this.responseDataCal.info.car_out_time;
          this.calData.parkingTime =
            this.responseDataCal.info.parking_time + " " + unitTime;

          this.calData.parkingFee = this.responseDataCal.info.parking_fee;
          this.calData.menchantId = this.responseDataCal.info.merchant_id;
          this.calData.partnerUId = this.responseDataCal.info.partner_uid;
          this.calData.reference1 = this.responseDataCal.info.reference1;
          this.calData.currencyCode = this.responseDataCal.info.currency_code;
          this.calData.shopName = this.responseDataCal.info.shop_name;
          this.calData.paymentId = this.responseDataCal.info.payment_id;
          this.calData.parkingTimeHr =
            this.responseDataCal.info.parking_time_hour;

          if (this.responseDataCal.info.parking_fee !== 0) {
            this.paymentBtnStatus = 1;
          } else {
            this.paymentBtnStatus = 0;
          }

          console.log(this.paymentBtnStatus);

          localStorage.setItem(
            "calData",
            JSON.stringify(this.responseDataCal.info)
          );
        })
        .catch((error) => {
          
          if (loading && calBtn) {
            loading.style.display = "none";
            calBtn.disabled = false;
          }
          this.responseDataCal = error.response.data;
          this.status = error.response.data.status;
          if (
            error.response.data.payment_status &&
            error.response.data.payment_status == 1
          ) {
            this.sessionStatus = 0;
            this.paymentStatus = error.response.data.payment_status;
          } else {
            localStorage.setItem("calPayment", 0);
            if (this.$store.state.locale == "th") {
              this.messageError = error.response.data.message_th;
            } else {
              this.messageError = error.response.data.message_en;
            }
          }
        });

      console.log(this.responseDataCal);
    },
    paymentParking() {
      const paymentStamp = new bootstrap.Modal(
        document.getElementById("payment-stamp")
      );

      if (this.calData.shopName === "") {
        paymentStamp.show();
      } else {
        this.payNow();
      }
    },
    payLater() {
      const paymentStamp = new bootstrap.Modal(
        document.getElementById("payment-stamp")
      );
      paymentStamp.hide();
    },
    payNow() {
      if (this.calData.parkingFee === 0) {
        const qs = require("qs");
        const urlAPI =
          process.env.VUE_APP_ROOT_API + "/v2/car/payment-parkingfee";
        const dataPost = qs.stringify({
          qrkey: this.calData.paymentId,
          money: this.calData.parkingFee,
          change: 0,
          net_fee: 0,
          parking_time: this.calData.parkingTimeHr,
          type: 0,
          channel: 5,
        });
        const headers = {
          Authorization: process.env.VUE_APP_AUTHORIZATION,
          "Content-Type": "application/x-www-form-urlencoded",
        };

        axios
          .post(urlAPI, dataPost, { headers })
          .then((res) => {
            this.paymentStatus = 1;
            this.status = 0;
            this.responseDataPay = res.data;
          })
          .catch((error) => {
            this.responseDataPay = error.response.data;
            this.status = error.response.data.status;
          });
        console.log(this.responseDataPay);
      } else {
        this.$router.push({
          name: "paymentparking",
          params: {
            merchant_id: this.calData.menchantId,
            partner_uid: this.calData.partnerUId,
            qr_type: "3",
            reference1: this.calData.reference1,
            amount: this.calData.parkingFee,
            currency_code: this.calData.currencyCode,
            callAPI: true,
            qrcodeKey: this.calData.pinCode,
          },
        });
      }
    },
  },
};
</script>

<style>
.calparking {
  position: relative;
  margin: 0;
  min-height: 80vh;
  padding: 20px;
}

.header-parking {
  display: block;
  margin-bottom: 10px;
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5b5b;
}

.text-parking {
  display: block;
  /* padding-left: 16px; */
  margin-bottom: 10px;
  width: 100%;
  height: auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5c5b5b;
}

.input-parking {
  margin-bottom: 5px;
}

.size-input {
  width: 100% !important;
  height: 30px;
}

.error-custom {
  color: red;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
}

.column {
  float: left;
  width: 50% !important;
}

.btn-scan-qr {
  position: relative;
  background-color: #3f4955;
  color: white;
  padding: 5px;
  width: 100%;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.btn-scan-qr:hover {
  background: #252a31 !important;
  color: #ffffff !important;
}

.btn-calculate {
  position: relative;
  background-color: #00c714;
  color: white;
  padding: 5px;
  /* margin-right: 10px; */
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn-calculate:hover {
  background: #00af12 !important;
  color: #ffffff !important;
}

.labal-scanner {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
}

.labal-btn {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 15px;
  margin: 0px;
}

.loader-custom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.payment-header {
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #2dc86e;
  display: inline;
  margin-right: 2%;
}

.payment-success {
  display: inline;
  width: 50px;
  height: 45px;
}

.payment-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 2;
}

.btn-payment {
  background-color: #3f4955;
  color: white;
  padding: 8px;
  margin-top: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn-payment-disable {
  background-color: grey;
  color: white;
  padding: 8px;
  margin-top: 40px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.custom-table {
  margin-top: 5%;
}

table {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

tr:nth-child(odd) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

td,
th {
  border: 1px solid #b9b8b8 !important;
  text-align: left;
  padding: 8px;
  font-size: 14px;
  width: 50%;
  color: #5c5b5b;
}

.policy-custom {
  position: absolute;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 12px;
  margin-top: 20px;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, 50%);
}

.session-header {
  width: 100%;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.05;
  letter-spacing: normal;
  text-align: left;
  color: #ff5757;
  display: inline;
  margin-right: 2%;
}

.payment-error {
  display: inline;
  width: 40px;
  height: 40px;
}

.stamp-header {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-top: 5%;
}

.stamp-message {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.btn-stamp {
  background-color: #3f4955 !important;
  color: white !important;
  width: 60%;
}
</style>
