<template>
  <div>
    <nav class="navbar navbar-custom">
      <div class="container-fluid">
        <div class="position-relative">
          <a class="navbar-brand">
            <img
              class="logo-img"
              src="../assets/logo_letmein.png"
              alt="Letmein LOGO"
            >
          </a>
        <select v-model="locale" @change="reload()" class="form-select right" id="langSelected" aria-label="Default select example">
          <option v-for="(locale, i) in language" :key="`locale${i}`" :value="i"> 
            {{ locale }}
          </option>
        </select>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'Navbar',
  data() {
    return {
      language: {
        en: "EN",
        th: "TH"
      }
    }
  },
  methods: {
    reload() {
      if (this.$route.name != "paymentparking") {
        this.$router.go()
      }
    }
  },
  computed: {
    locale: {
      get: function() {
        if (this.$route.name != "calparking" && this.$route.name != "parkingcondition") {
          this.$i18n.locale = this.$store.state.locale
        }
        console.log("get: ", this.$store.state.locale);
        return this.$store.state.locale
      },
      set: function(newVal) {
        console.log("set: ", newVal);
        this.$store.dispatch('changeLocale', newVal)
      }
    }
  },
}
</script>

<style>
.navbar-custom {
  background-color: #3f4855;
  padding: 16px 0;
}

.right {
  position: absolute;
  right: 0%;
  top: 50%;
  width: 10% !important;
  transform: translate(10%, -100%);
  padding: 5px !important;
  background-color: transparent !important;
  border: none !important;
  color: white !important;
  border-bottom: 1px solid !important;
}

.logo-img {
  width: 55%;
  height: auto;
}
</style>
