<template>
  <div class="condtion">
    <h1>{{ $t("conditionLabel") }}</h1>

    <div class="loader-custom">
      <div
        id="loading"
        class="spinner-grow text-secondary"
        role="status"
        style="display: none"
      />
    </div>
    <div class="condition-text">
      <p v-for="(item, index) in parkingCodition" :key="index">
        {{ item }}
      </p>
    </div>

    <button type="button" class="btn-payment" @click="backToHome">
      {{ $t("btnBack") }}
    </button>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "ParkingCondition",
  data() {
    return {
      parkingCodition: [],
    };
  },
  created() {
    this.$i18n.locale = this.$store.state.locale;

    if (this.$store.state.locale == "th") {
      this.getParkingCondition("th");
    } else {
      this.getParkingCondition("en");
    }
  },
  methods: {
    backToHome() {
      // this.$router.go(-1)
      // localStorage.setItem("goBack", 1);
      // this.$router.back();
      this.$router.push({
        name: 'calparking'
      })
    },
    async getParkingCondition(language) {
      const loading = document.getElementById("loading");
      const qs = require("qs");
      const urlAPI = process.env.VUE_APP_ROOT_API + "/v2/car/parking-condition";
      const dataPost = qs.stringify({
        visitor_type: 3,
        lang: language,
      });
      const headers = {
        Authorization: process.env.VUE_APP_AUTHORIZATION,
        "Content-Type": "application/x-www-form-urlencoded",
      };

      if (loading) {
        loading.style.display = "block";
      }

      await axios
        .post(urlAPI, dataPost, { headers })
        .then((res) => {
          if (loading) {
            loading.style.display = "none";
          }

          const length = res.data.info.length;

          for (let i = 0; i < length; i++) {
            this.parkingCodition.push(res.data.info[i].condition_value);
          }

          // localStorage.setItem(
          //   'parkingCondition',
          //   JSON.stringify(this.parkingCodition)
          // )
        })
        .catch((error) => {
          console.log(error.response.data);
        });
    },
  },
};
</script>

<style>
.condtion {
  padding: 20px;
  padding-top: 30px;
}

.condition-text {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  margin-top: 20px;
}
</style>
