<template>
  <div style="background-color: #2c3239; min-height: 100vh">
    <nav class="navbar-qrscanner">
      <div class="container-fluid">
        <div class="position-relative">
          <a
            class="navbar-brand"
            style="color: white"
          > QR Code Reader </a>
          <img
            class="xcross-right"
            src="../assets/xcross-logo.png"
            alt="xcross"
            @click="backToHome"
          >
        </div>
      </div>
    </nav>
    <div class="border-custom">
      <img
        class="qrcode-border"
        src="../assets/qrcode-border.png"
        alt="qrcodeBorder"
      >
      <qrcode-stream
        class="custom-camera"
        @decode="decode"
        @init="onInit"
      />
    </div>
    <p class="text-qrcode">
      {{ $t('scanMessage') }}
    </p>
  </div>
</template>

<script>
import {
  QrcodeStream,
  QrcodeDropZone,
  QrcodeCapture
} from 'vue3-qrcode-reader'

export default {
  name: 'QRcodeScanner',
  components: {
    QrcodeStream,
    QrcodeDropZone,
    QrcodeCapture
  },
  data () {
    return {
      qrcodeKey: ''
    }
  },
  methods: {
    decode (decodeString) {
      this.qrcodeKey = decodeString
      //   console.log(this.qrcodeKey);
      this.$router.push({
        name: 'calparking',
        params: { qrkey: this.qrcodeKey }
      })
    },
    backToHome () {
      this.$router.push({
        name: 'calparking'
      })
    }
  }
}
</script>

<style>
.xcross-right {
  position: absolute;
  right: 0%;
  top: 0%;
  width: 30px;
  height: auto;
  padding: 5px;
  background-color: transparent;
  border: none;
  color: white;
}

.text-qrcode {
  color: white;
  font-size: 14px;
  margin-top: 200px;
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 0px;
}
.border-custom {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  width: -webkit-fit-content;
}
.qrcode-border {
  width: 250px;
  height: 250px;
}

.custom-camera {
  position: absolute !important;
  top: 10%;
  left: 9%;
  width: 200px !important;
  height: 200px !important;
}

.camera {
  padding: 16px;
  text-align: center;
}

.navbar-qrscanner {
  text-align: center;
  background-color: #3f4855;
  padding: 30px 0;
}
</style>
