<template>
  <div class="qrpayment">
    
    <p class="header-payment">
      {{ $t("paymentTitle") }} <span id="countDownTimer">{{ timeLimit }}</span> {{ $t('timeUnit') }}
    </p>
    <div class="qrcode-img">
      <img
        :src="imgQr"
        style="width: 250px; hight: 250px"
      >
      <div
        v-if="imgQr === ''"
        class="imgqrpayment"
      >
        <div class="loader-qrpayment">
          <div
            id="loading-payment"
            class="spinner-grow text-secondary"
            role="status"
            style="display: block"
          />
        </div>
      </div>
    </div>
    <div
      class="row"
      style="margin-top: 15px"
    >
      <div class="column-btn-qrpayment">
        <button
          class="btn-download"
          @click="downloadQRCode"
        >
          <img
            src="../assets/download-logo.png"
            style="
              width: 16px;
              vertical-align: middle;
              display: inline-block;
              margin-right: 10px;
            "
          >
          <span>{{ $t('btnSave') }}</span>
        </button>
      </div>
    </div>

    <p class="font-message">
      {{ $t('descriptionPayment') }}
    </p>

    <div class="warning-box">
      <p>{{ $t('warningPayment1') }}</p>
      <p>{{ $t('warningPayment2') }}</p>
    </div>

    <div
      v-if="autoCheckPayment === 1"
      style="margin: 0"
    >
      <div
        class="row"
        style="margin-top: 60px"
      >
        <div class="column-btn-qrpayment">
          <button
            class="btn-cancel"
            @click="cancelPayment"
          >
            <p class="labal-btn">
              {{ $t('btnCancel') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <div
      v-else-if="autoCheckPayment === 0"
      style="margin: 0"
    >
      <div
        class="row"
        style="margin-top: 60px"
      >
        <div class="column">
          <button
            class="btn-cancel"
            @click="cancelPayment"
          >
            <p class="labal-btn">
              {{ $t('btnCancel') }}
            </p>
          </button>
        </div>
        <div class="column">
          <button
            class="btn-check-payment"
            @click="checkPayment"
          >
            <p class="labal-btn">
              {{ $t('btnCheckStatus') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="policy-custom">
      <a
        href="/condition"
        style="color: #5c5b5b"
      >{{ $t('parkingCondition') }}</a>
    </div> -->
  </div>

  <div
    id="session-timeout"
    ref="sessionExpire"
    class="modal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div
          class="modal-header"
          style="border-bottom: 0"
        >
          <!-- <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button> -->
        </div>
        <div class="modal-body text-center">
          <img
            class="errorLogo"
            src="../assets/error-logo.png"
            alt="errorLogo"
          >
          <h3>{{ $t('errorTitle') }}</h3>
          <p>{{ $t('errorMessage') }}</p>
          <button
            class="btn btn-sesion"
            data-bs-dismiss="modal"
            @click="sessionExpire"
          >
            {{ $t('btnOk') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bootstrap from 'bootstrap/dist/js/bootstrap.js'
import axios from 'axios'
export default {
  name: 'QRcodePayment',
  props: [
    'merchant_id',
    'partner_uid',
    'qr_type',
    'reference1',
    'amount',
    'currency_code',
    'callAPI',
    'qrcodeKey'
  ],
  data () {
    return {
      qrKey: '',
      imgQr: '',
      paymentId: '',
      calData: {},
      callCheck: '',
      paymentStatus: 0,
      timeLimit: 10,
      autoCheckPayment: 1,
      autoCount: 0,
      menchantId: '',
      partnerUId: ''
    }
  },
  created () {

    if (this.callAPI) {
      this.getQrcodeKey()
    }
    if (localStorage.getItem('qrPayment')) {
      this.imgQr = localStorage.getItem('qrPayment')
    }
    if (localStorage.getItem('calData')) {
      this.calData = localStorage.getItem('calData')
      this.calData = JSON.parse(this.calData)
      this.paymentId = this.calData.payment_id
      this.menchantId = this.calData.merchant_id
      this.partnerUId = this.calData.partner_uid
    } else {
      this.$router.push({
        name: 'calparking'
      })
    }
  },
  mounted () {
    this.checkPayment(), this.countdown()
  },
  beforeUnmount () {
    console.log('Clear Interval')
    clearInterval(this.callCheck)
  },
  methods: {
    async getQrcodeKey () {
      const urlAPI = process.env.VUE_APP_PAYMENT_API + '/v1/payment/promptpay/qr'
      const dataPost = {
        merchant_id: this.merchant_id,
        partner_uid: this.partner_uid,
        qr_type: this.qr_type,
        reference1: this.reference1,
        amount: this.amount,
        currency_code: this.currency_code
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axios
        .post(urlAPI, dataPost, { headers })
        .then((res) => {
          console.log('/////////Get QR Code Key/////////')
          console.log(res.data)
          this.qrKey = res.data.qr_code
          this.qrimage()
        })
        .catch((error) => {
          console.log(error)
          //   this.responseDataCal = error.response.data;
          //   this.messageError = error.response.data.message;
          //   this.status = error.response.data.status;
        })
    },
    async qrimage () {
      // var imgLoader = document.getElementById('img-tran');
      // imgLoader.style.display = 'block'
      const urlAPI = process.env.VUE_APP_PAYMENT_API + '/v1/qrimage/promptpay'
      const dataPost = {
        qr_key: this.qrKey
      }
      const headers = {
        'Content-Type': 'application/json'
      }

      await axios
        .post(urlAPI, dataPost, { headers })
        .then((res) => {
          console.log('/////////Get Img QR Code/////////')
          console.log(res.data)
          this.imgQr = 'data:image/png;base64,' + res.data.image_base64
          // imgLoader.style.display = 'none'
          localStorage.setItem('qrPayment', this.imgQr)
        })
        .catch((error) => {
          console.log(error)
          //   this.responseDataCal = error.response.data;
          //   this.messageError = error.response.data.message;
          //   this.status = error.response.data.status;
        })
    },
    downloadQRCode () {
      const linkSource = this.imgQr
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = 'QRcode.png'
      downloadLink.click()
    },
    async cancelPayment () {
      
      // const qs = require('qs')
      const urlAPI =
        process.env.VUE_APP_PAYMENT_API + '/v1/payment/promptpay/qr/cancel'

      const headers = {
        'Content-Type': 'application/json'
      }

      const dataPost = {
        merchant_id: this.menchantId,
        cancel_partner_uid: this.partnerUId
      }

      await axios
        .post(urlAPI, dataPost, { headers })
        .then((res) => {
          console.log(res.data)
          console.log('status: ', res.data.status)
          console.log(res.data.message)
          
          this.$router.push({
            name: 'calparking'
          })
        })
        .catch((error) => {
          console.log(error)
          this.$router.push({
            name: 'calparking'
          })
        })
    },
    async checkPayment () {
      const qs = require('qs')
      const deley = 3000
      const callTime = 60000

      const urlAPI =
        process.env.VUE_APP_ROOT_API + '/v3/payment/check-payment-status'

      const dataPost = qs.stringify({
        payment_id: this.paymentId
      })

      const headers = {
        Authorization: process.env.VUE_APP_AUTHORIZATION,
        'Content-Type': 'application/x-www-form-urlencoded'
      }

      this.callCheck = setInterval(
        () =>
          axios
            .post(urlAPI, dataPost, { headers })
            .then((res) => {
              console.log(res.data)
              if (res.data.status === 1) {
                localStorage.setItem(
                  'paymentInfo',
                  JSON.stringify(res.data.info)
                )
                localStorage.setItem(
                  'abbTaxID',
                  JSON.stringify(res.data.abb_tax)
                )
                localStorage.setItem(
                  'paymentMethod',
                  JSON.stringify(res.data.payment_method)
                )
                // this.paymentStatus = 1;
                this.$router.push({
                  name: 'taxinvoice'
                })
              } else {
                this.autoCount = this.autoCount + deley
                console.log('count time: ', this.autoCount)
                console.log(res.data.message)
                if (this.autoCount >= callTime) {
                  this.autoCheckPayment = 0
                  console.log('Clear Interval')
                  clearInterval(this.callCheck)
                }
              }
            })
            .catch((error) => {
              console.log(error)
              //   this.responseDataCal = error.response.data;
              //   this.messageError = error.response.data.message;
              //   this.status = error.response.data.status;
            }),
        deley
      )
    },
    sessionExpire () {
      this.$router.push({
        name: 'calparking',
        params: { sessionExpire: 1, pinCode: this.qrcodeKey }
      })
    },
    countdown () {
      let element, endTime, hours, mins, msLeft, time

      function twoDigits (n) {
        return n <= 9 ? '0' + n : n
      }

      function updateTimer () {
        msLeft = endTime - +new Date()
        if (msLeft < 1000) {
          const sessionExpire = new bootstrap.Modal(
            document.getElementById('session-timeout')
          )
          sessionExpire.show()
        } else {
          time = new Date(msLeft)
          hours = time.getUTCHours()
          mins = time.getUTCMinutes()
          element.innerHTML =
            (hours ? hours + ':' + twoDigits(mins) : mins) +
            ':' +
            twoDigits(time.getUTCSeconds())
          setTimeout(updateTimer, time.getUTCMilliseconds() + 500)
        }
      }

      element = document.getElementById('countDownTimer')
      endTime = +new Date() + 1000 * (60 * this.timeLimit) + 500
      updateTimer()
    }
  }
}
</script>

<style>
.qrpayment {
  position: relative;
  margin: 0;
  min-height: 80vh;
  padding: 20px;
}

.header-payment {
  text-align: center;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  color: #5c5b5b;
}

.loader-qrpayment {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, 0%);
}

div.imgqrpayment {
  /* position: relative;
  left: 50%;
  transform: translate(-50%, 0%); */
  margin: auto;
  /* opacity: 0.1; */
  width: 250px;
  height: 250px;
  /* background-color: #4caf50; */
}

.qrcode-img {
  text-align: center;
}

.column-btn-qrpayment {
  float: left;
  width: 100% !important;
  text-align: center;
}

.btn-download {
  background-color: #00c714;
  color: white;
  padding: 10px;
  border-radius: 5px;
  border: none;
}

.btn-download:hover {
  background: #00af12 !important;
  color: #ffffff !important;
}

.btn-sesion {
  background-color: #3f4955 !important;
  color: white !important;
  width: 40%;
}

.font-message {
  text-align: center;
  padding-top: 18px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  margin-bottom: 5px;
  color: #5c5b5b;
}

.warning-box {
  position: relative;
  text-align: center;
  font-size: 14px;
  padding-top: 15px;
  padding-bottom: 2px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  color: red;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  width: 100%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 30%);
}

.btn-cancel {
  background-color: #a5a9ac;
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn-cancel:hover {
  background: #818588 !important;
  color: #ffffff !important;
}

.btn-check-payment {
  position: relative;
  background-color: #00c714;
  color: white;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  border: none;
}

.btn-check-payment:hover {
  background: #00af12 !important;
  color: #ffffff !important;
}

.errorLogo {
  width: 150px;
  height: auto;
}
</style>
