<template>
  <Navbar v-if="$route.name != 'qrscanner'" />
  <router-view :key="$route.name" />
</template>

<script>
import Navbar from './components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  mounted () {
    document.title = 'LETMEIN: Smart Living and Workplace Platform'
    const link = document.querySelector("[rel='icon']")
    link.setAttribute('href', 'icon.png')
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  margin: 0;
  height: 100%;
  /* overflow: hidden; */
  position: relative;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
