import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import CalParking from '../views/CalParking.vue'
import QRcodeScanner from '../views/QRcodeScanner.vue'
import QRcodePayment from '../views/QRcodePayment.vue'
import TaxInvoice from '../views/TaxInvoice.vue'
import ParkingCondition from '../views/ParkingCondition.vue'

const routes = [
  {
    path: '/',
    name: 'calparking',
    component: CalParking,
    props: true
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }

  {
    path: '/scanner',
    name: 'qrscanner',
    component: QRcodeScanner,
    props: true
  },
  {
    path: '/payment',
    name: 'paymentparking',
    component: QRcodePayment,
    props: true
  },
  {
    path: '/invoice',
    name: 'taxinvoice',
    component: TaxInvoice,
    props: true
  },
  {
    path: '/condition',
    name: 'parkingcondition',
    component: ParkingCondition
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
